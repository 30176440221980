import { createReducer, on } from '@ngrx/store';
import { GlobalApiActions } from '.';
import { GlobalState } from 'src/app/app.state';

const initialState = {
  state: [],
  showroomsAll: [],
  loadedState: false,
  carantyhostCRM: [],
  listAllCHostCompany: [],
  listCHostAquienPuedeVer: [],
  loadedCarantyhostCRM: false,
  banks: [],
  loadedBanks: false,
  userSession: {},
  loadingGraph: false,
  scopesGraph: [],
  menuGraph: [],
  carsPublish: [],
  usersNotificaMC: [],
  usersAutorizaComisionContrato: [],
  usersNotificaMCSolicitudContrato: {},
  usersNotificaSolicitudPago: {},
  usersNotificaSupervisaContrato: null,
  usersNotificaControlInternoContrato: null,
  talleresInspeccion: [],
};

export const stateGlobalReducer = createReducer<GlobalState>(
  initialState,
  on(
    GlobalApiActions.loadSuccessCatalogueState,
    (state, action): GlobalState => ({
      ...state,
      state: action.state,
      loadedState: true,
    })
  ),

  on(
    GlobalApiActions.loadSuccessCatalogueShowroomAll,
    (state, action): GlobalState => ({
      ...state,
      showroomsAll: action.showroomsAll,
    })
  ),

  on(
    GlobalApiActions.loadSuccessCatalogueCHostCRM,
    (state, action): GlobalState => ({
      ...state,
      carantyhostCRM: action.carantyhostCRM,
      loadedCarantyhostCRM: true,
    })
  ),

  on(
    GlobalApiActions.loadSuccessListChostAllCompany,
    (state, action): GlobalState => ({
      ...state,
      listAllCHostCompany: action.listChost,
      listCHostAquienPuedeVer: action.listCHostAquienPuedeVer,
    })
  ),

  on(
    GlobalApiActions.loadSuccessCatalogueBanks,
    (state, action): GlobalState => ({
      ...state,
      banks: action.banks,
      loadedBanks: true,
    })
  ),

  on(
    GlobalApiActions.loadSuccessUserSession,
    (state, action): GlobalState => ({
      ...state,
      userSession: action.userProfile,
      usersNotificaMC: action.usersNotificaMC,
      usersAutorizaComisionContrato: action.usersAutorizaComisionContrato,
      usersNotificaMCSolicitudContrato: action.usersNotificaMCSolicitudContrato,
      usersNotificaSolicitudPago: action.usersNotificaSolicitudPago,
      usersNotificaSupervisaContrato: action.usersNotificaSupervisaContrato,
      usersNotificaControlInternoContrato: action.usersNotificaControlInternoContrato,
      loadingGraph: true,
    })
  ),

  on(
    GlobalApiActions.loadSuccessGetCarsPublish,
    (state, action): GlobalState => ({
      ...state,
      carsPublish: action.cars,
    })
  ),

  on(
    GlobalApiActions.loadSuccessloadResorcesCRM,
    (state, action): GlobalState => ({
      ...state,
      resourcesCRM: action.resourcesCRM,
    })
  ),

  on(
    GlobalApiActions.loadSuccessloadTallerInspeccion,
    (state, action): GlobalState => ({
      ...state,
      talleresInspeccion: action.talleresInspeccion,
    })
  )
);
