import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { prospecto } from '../models/prospecto.model';
import {
  ResponseApiUploadProspectosFacebook,
  ResponseBackAzure,
  ResponseBackAzureCRM,
} from '@core/models/responsehttp.model';

@Injectable({
  providedIn: 'root',
})
export class ProspectosService {
  private readonly _http = inject(HttpClient);

  // Obtener listado de fuentes primarias
  getFuentePrimaria(): Observable<any> {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/fuenteprimaria?backend=azure-crm`);
  }

  // Obtener listado de canales
  getCanales(): Observable<any> {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/canal?backend=azure-crm`);
  }

  // Obtener listado de estados
  getEstado(): Observable<any> {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/estado?backend=azure-crm`);
  }

  // Obtener listado de etapas
  getEtapa(): Observable<any> {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/etapa?backend=azure-crm`);
  }

  // Obtener listado de Marcas
  getMarca(): Observable<any> {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/getMarcas?backend=azure`);
  }

  // Obtener listado de Modelos
  getModelo(id_Marca: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/getModelos?backend=azure&id_marca=${id_Marca}`
    );
  }

  // Obtener listado año de auto
  getAnioAuto(id_modelo: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/getAnios?backend=azure&id_modelo=${id_modelo}`
    );
  }

  // Obtener listado de version de auto
  getVersion(id_anio: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/getVersion?backend=azure&id_anio=${id_anio}`
    );
  }

  // Obtener listado tipos de auto
  getTiposAutomovil() {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/tiposautomovil?backend=azure-crm`);
  }

  // Obtener listado tipos de tranmision
  getTransmision() {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/transmisionautomovil?backend=azure-crm`
    );
  }

  // Obtener listado interes usuario
  //TODO: agregar el id del automovil
  getInteresUsuario(id_prospecto: string): Observable<any> {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/prospectoautomovilinteresbyid?backend=azure-crm&id_prospecto=${id_prospecto}`
    );
  }

  // Delete interes
  deleteInteres(Idinteres: string): Observable<any> {
    return this._http.delete(
      `${environment.API_URL_MANAGEMENT}/prospectoautomovilinteres2?backend=azure-crm&id_automovil=${Idinteres}`
    );
  }

  // Post prospecto
  postInteres(interesDetail: any): Observable<any> {
    return this._http
      .post(
        `${environment.API_URL_MANAGEMENT}/prospectoautomovilinteres?backend=azure-crm`,
        JSON.stringify(interesDetail)
      )
      .pipe(map((response: any) => <any>response));
  }

  // listar prospectos con Paginacion
  getProspectosPagina(pagina_seleccionada: number, numero_elementos: number): Observable<any> {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/prospecto?backend=azure-crm&pagina_seleccionada=${pagina_seleccionada}&elementos_pagina=${numero_elementos}`
    );
  }

  // Get prospecto by ID
  getProspecto(id_prospecto: string): Observable<any> {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/prospectobyid?id=${id_prospecto}&backend=azure-crm`);
  }

  // Get prospecto by Name
  getProspectoByName(nombre_prospecto: string): Observable<any> {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/prospectobyname?backend=azure-crm&nombre_prospecto=${nombre_prospecto}`
    );
  }

  //Prospecto mas paginacion
  getBuquedaProspectoPaginado(
    nombre_prospecto: string,
    pagina_seleccionada: number,
    elementos_pagina: number
  ): Observable<any> {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/prospectobynamepagination?backend=azure-crm&nombre_prospecto=${nombre_prospecto}&pagina_seleccionada=${pagina_seleccionada}&elementos_pagina=${elementos_pagina}`
    );
  }

  // Post prospecto
  postProspecto(prospectoDetail): Observable<any> {
    return this._http
      .post(`${environment.API_URL_MANAGEMENT}/prospecto?backend=azure-crm`, JSON.stringify(prospectoDetail))
      .pipe(map((response: any) => <any>response));
  }

  // Put prospecto
  putProspecto(prospectoDetail: prospecto): Observable<any> {
    return this._http
      .put(`${environment.API_URL_MANAGEMENT}/prospecto?backend=azure-crm`, JSON.stringify(prospectoDetail))
      .pipe(map((response: any) => <any>response));
  }

  // Delete prospecto
  deleteProspecto(prospectoDetail: any): Observable<any> {
    return this._http.delete(`${environment.WebApiUrlCRM}/api/prospecto/?id_prospecto=${prospectoDetail}`);
  }

  // Get prospectocounter
  getContadores(): Observable<any> {
    return this._http.get(`${environment.WebApiUrlCRM}/api/prospectocounter`);
  }

  // Get ubicacion
  getEstadoClave() {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/get_estado_clave?backend=azure`);
  }

  // Post Coments
  prospectocomentario(commentDetail: any): Observable<any> {
    return this._http
      .put(`${environment.API_URL_MANAGEMENT}/prospectocomentario?backend=azure-crm`, JSON.stringify(commentDetail))
      .pipe(map((response: any) => <any>response));
  }

  getCarantyHost() {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/crm/usuarios?backend=azure`);
  }

  //Validar que no exista el mismo registro en prospecto
  validateInProspecto(body: any) {
    return this._http.post(`${environment.API_URL_MANAGEMENT}/prospecto/search?backend=azure-crm`, body);
  }

  countProspectoWhitEtapa(id_etapa: string) {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/prospectos/etapa/${id_etapa}?backend=azure-crm`);
  }

  getMotivoPerdidaProspecto() {
    return this._http.get<ResponseBackAzureCRM>(
      `${environment.API_URL_MANAGEMENT}/prospectos/motivos/cancelacion?backend=azure-crm`
    );
  }

  uploadProspectosFacebook(body: any) {
    return this._http.post<ResponseApiUploadProspectosFacebook>(
      `${environment.API_URL_MANAGEMENT}/facebook/clientes/potenciales?backend=azure`,
      body
    );
  }
}
