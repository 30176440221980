import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseBackAzure } from '@core/models/responsehttp.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private _http: HttpClient) {}

  getByRolUserCombineGraph(grupos: string[]) {
    return this._http.post<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/citas/permisos/graph?backend=azure`,
      {
        grupos: grupos,
        origen: 'crm',
      }
    );
  }
}
