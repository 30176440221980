import { Injectable, inject } from '@angular/core';
import { ItemCarantyHostCRM, ListEstado, ShowRoomStateAll } from '@core/models/responsehttp.model';
import { AlertService } from '@core/services/alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest, firstValueFrom } from 'rxjs';
import { map, catchError, tap, concatMap, switchMap } from 'rxjs/operators';
import { ProspectosService } from 'src/app/usuario/prospectos/services/prospectos.service';
import { GlobalApiActions } from '.';
import { GraphService } from '@core/services/graph.service';
import { MenuItem, UserSession } from '@core/models/user-roles.model';
import { ProfileType } from '@core/models/utils.model';
import { CatalogosGeneralesService } from '@core/services/catalogos-generales.service';
import { RolesService } from '@core/services/roles.service';
import { deleteDuplicateArrayObjects } from '@shared/utils/utils';
import { AutosService } from 'src/app/autos/services/autos.service';
import { CarFirebaseObject } from '@core/models/car-firebase.model';
import { StoreLocalStorageAuthService } from '@auth/store-localStorage-auth.service';
import { NotificationObject, UsersNotificaBase } from 'src/app/contratos/models/contrato.model';
import { InspecionesService } from 'src/app/pagos-inspeccion/services/inspeciones.service';
import { TallerInspeccionObject } from 'src/app/pagos-inspeccion/models/pagos-inspeccion.model';

@Injectable()
export class StateGlobalEffects {
  private readonly _prospectosService = inject(ProspectosService);
  private readonly _notificationsService = inject(AlertService);
  private readonly _catalogosGeneralesService = inject(CatalogosGeneralesService);

  private readonly _graphService = inject(GraphService);
  private readonly _rolService = inject(RolesService);
  private readonly _autosService = inject(AutosService);
  private readonly _storeAuthLocalStorage = inject(StoreLocalStorageAuthService);
  private readonly _inspecionesService = inject(InspecionesService);

  constructor(private actions$: Actions) {}

  GET_RESOURCES_CRM$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initLoadResorces),
      concatMap(() =>
        this._catalogosGeneralesService.getResourcesCRM().pipe(
          map((response) => {
            let resourcesCRM: any;

            if (response.code === 200 && response.data.length > 0) resourcesCRM = response.data[0];

            return GlobalApiActions.loadSuccessloadResorcesCRM({ resourcesCRM });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_SHOWROOMS_ALL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueShowroomAll, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._catalogosGeneralesService.getShowRoomsArray().pipe(
          map((response) => {
            if (response.code === 200 && Array.isArray(response.result) && response.result.length > 0)
              return GlobalApiActions.loadSuccessCatalogueShowroomAll({
                showroomsAll: response.result as ShowRoomStateAll[],
              });

            return GlobalApiActions.loadErrorCatalogueShowroomAll({
              errorMessage: 'Error al cargar catálogo de showrooms',
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_ESTADO_CLAVE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueState, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._prospectosService.getEstadoClave().pipe(
          map((response) => {
            let state: ListEstado[];
            if (response.code === 200 && Array.isArray(response.result)) {
              state = response.result as ListEstado[];
            }
            return GlobalApiActions.loadSuccessCatalogueState({ state });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_CARANTYHOST_CRM$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueCHostCRM, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._catalogosGeneralesService.getListadoUsuariosCRM().pipe(
          map((response) => {
            let carantyhostCRM: ItemCarantyHostCRM[];
            if (response.code === 200 && Array.isArray(response.response)) {
              carantyhostCRM = response.response as ItemCarantyHostCRM[];
            }
            return GlobalApiActions.loadSuccessCatalogueCHostCRM({ carantyhostCRM });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_BANKS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueBanks, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._catalogosGeneralesService.getDataBanks().pipe(
          map((response) => {
            let banks: string[];
            if (response.code === 200) {
              banks = response.result as string[];
            }
            return GlobalApiActions.loadSuccessCatalogueBanks({ banks });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_USER_PROFILE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initUserSession),
      concatMap(() =>
        combineLatest([
          this._graphService.getMeProfile(),
          //this._graphService.getMeProfileData(),
          this._graphService.getMyGroups(),
          this._graphService.getMembersGroups('82c98979-7735-4afb-9cb0-9f7f9f92f2ab'),
          this._graphService.getMembersGroups('a3d1f3a4-29d5-4da6-96fb-5d6a6a6b2e34'),
          this._graphService.getMembersGroups('ba99fe70-c730-491d-b47c-0784cfca7eb6'),
          this._graphService.getMembersGroups('0f0dd293-8a4a-43b8-b42d-52bdd36aa631'),
          this._graphService.getMembersGroups('1b162dd1-8955-4333-930a-d2d77f060cda'),
          this._graphService.getMembersGroups('b3c223d5-41e5-4890-aa10-5a2eae14b78b'),
        ]).pipe(
          map(
            ([
              profile,
              groups,
              _usersNotificaMC,
              _usersNotificaAutComision,
              _notificaMCSolContrato,
              _notificationSolicitudContrato,
              _notificationSupervisorContrato,
              _notificationControlInternoContrato,
            ]) => {
              const userProfile: UserSession = {};
              let usersAutorizaComisionContrato: string[] = [];
              let usersNotificaMC: string[] = [];
              let usersNotificaMCSolicitudContrato: NotificationObject = {};
              let usersNotificaSolicitudPago: NotificationObject = {};
              let usersNotificaSupervisaContrato: UsersNotificaBase = { email: [], number: [] };
              let usersNotificaControlInternoContrato: UsersNotificaBase = { email: [], number: [] };

              if (profile) {
                userProfile.profileGraph = profile as ProfileType;

                const profileToken = {
                  uniqueId: profile.id,
                  username: profile.userPrincipalName,
                  name: profile.displayName,
                  environment: 'login.app.web',
                };

                usersNotificaMCSolicitudContrato.hostEmail = [profile.userPrincipalName];
                usersNotificaMCSolicitudContrato.hostNumber = [`${profile.mobilePhone}`];

                userProfile.profileToken = profileToken;
              }

              if (Array.isArray(groups.value) && groups.value.length > 0)
                userProfile.groupsGraph = groups.value.map((group) => group.displayName);

              if (Array.isArray(_usersNotificaMC.value) && _usersNotificaMC.value.length > 0)
                usersNotificaMC = _usersNotificaMC.value
                  .filter((user) => user.mobilePhone)
                  .map((user) => user.mobilePhone);

              if (Array.isArray(_usersNotificaAutComision.value) && _usersNotificaAutComision.value.length > 0)
                usersAutorizaComisionContrato = _usersNotificaAutComision.value
                  .filter((user) => user.mobilePhone)
                  .map((user) => user.mobilePhone);

              if (Array.isArray(_notificaMCSolContrato.value) && _notificaMCSolContrato.value.length > 0) {
                usersNotificaMCSolicitudContrato = {
                  ...usersNotificaMCSolicitudContrato,
                  mesa_controlEmail: _notificaMCSolContrato.value.map((user) => user.userPrincipalName),
                  mesa_controlNumber: _notificaMCSolContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationSolicitudContrato.value) &&
                _notificationSolicitudContrato.value.length > 0
              ) {
                usersNotificaSolicitudPago = {
                  ...usersNotificaSolicitudPago,
                  mesa_controlEmail: _notificationSolicitudContrato.value.map((user) => user.userPrincipalName),
                  mesa_controlNumber: _notificationSolicitudContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationSupervisorContrato.value) &&
                _notificationSupervisorContrato.value.length > 0
              ) {
                usersNotificaSupervisaContrato = {
                  email: _notificationSupervisorContrato.value.map((user) => user.userPrincipalName),
                  number: _notificationSupervisorContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationControlInternoContrato.value) &&
                _notificationControlInternoContrato.value.length > 0
              ) {
                usersNotificaControlInternoContrato = {
                  email: _notificationControlInternoContrato.value.map((user) => user.userPrincipalName),
                  number: _notificationControlInternoContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              return {
                userProfile,
                usersAutorizaComisionContrato,
                usersNotificaMC,
                usersNotificaMCSolicitudContrato,
                usersNotificaSolicitudPago,
                usersNotificaSupervisaContrato,
                usersNotificaControlInternoContrato,
              };
            }
          ),
          switchMap(
            async ({
              userProfile,
              usersAutorizaComisionContrato,
              usersNotificaMC,
              usersNotificaMCSolicitudContrato,
              usersNotificaSolicitudPago,
              usersNotificaSupervisaContrato,
              usersNotificaControlInternoContrato,
            }) => {
              const grupos = userProfile.groupsGraph ?? [];
              //const grupos = ['Usuario - CRM', 'Caranty'];

              const scopes = await firstValueFrom(this._rolService.getByRolUserCombineGraph(grupos));

              return {
                userProfile,
                scopes,
                usersAutorizaComisionContrato,
                usersNotificaMC,
                usersNotificaMCSolicitudContrato,
                usersNotificaSolicitudPago,
                usersNotificaSupervisaContrato,
                usersNotificaControlInternoContrato,
              };
            }
          ),

          map(
            ({
              userProfile,
              scopes,
              usersAutorizaComisionContrato,
              usersNotificaMC,
              usersNotificaMCSolicitudContrato,
              usersNotificaSolicitudPago,
              usersNotificaSupervisaContrato,
              usersNotificaControlInternoContrato,
            }) => {
              if (scopes.code === 200 && Array.isArray(scopes.result) && scopes.result.length > 0) {
                scopes.result.sort(function (a, b) {
                  if (a.orden > b.orden) {
                    return 1;
                  }
                  if (a.orden < b.orden) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                userProfile.menuGraph = deleteDuplicateArrayObjects(scopes.result) as MenuItem[];

                userProfile.scopesGraph = userProfile.menuGraph.map((rol: MenuItem) => rol.routerLink) as string[];

                userProfile.menuGraph = userProfile.menuGraph.filter((rol: MenuItem) => rol.visibleMenu);

                this._storeAuthLocalStorage.setScopesUser(userProfile.scopesGraph);
              }

              return GlobalApiActions.loadSuccessUserSession({
                userProfile,
                usersNotificaMC,
                usersAutorizaComisionContrato,
                usersNotificaMCSolicitudContrato,
                usersNotificaSolicitudPago,
                usersNotificaSupervisaContrato,
                usersNotificaControlInternoContrato,
              });
            }
          ),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_SHOWROOMS_FILTER$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initListChostAllCompany),
      switchMap((payload) =>
        combineLatest([
          this._graphService.getAllUsersCompany(),
          this._catalogosGeneralesService.getCarantyHostQuienPuedeVerAquien(payload.email, 'crm'),
        ]).pipe(
          map(([hostAll, hostFilter]) => {
            let listChost: ProfileType[] = [];
            let listCHostAquienPuedeVer: ProfileType[] = [];

            if (hostAll.value.length > 0) {
              listChost = hostAll.value;

              if (hostFilter.code === 200 && Array.isArray(hostFilter.result) && hostFilter.result.length > 0) {
                listCHostAquienPuedeVer = listChost.filter(
                  (item: ProfileType) =>
                    Array.isArray(hostFilter.result) && hostFilter.result.includes(item.userPrincipalName)
                );
              }
            }

            return GlobalApiActions.loadSuccessListChostAllCompany({
              listChost,
              listCHostAquienPuedeVer,
            });
          }),

          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_CARS_PUBLISH$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initGetCarsPublish),
      concatMap(() =>
        this._autosService.getPublicacionesByPage(1).pipe(
          map((response: any) => {
            const cars: CarFirebaseObject[] = [];

            if (response.data.publicaciones.length > 0) cars.push(...response.data.publicaciones);

            return GlobalApiActions.loadSuccessGetCarsPublish({ cars });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_TALLERES_INSPECCION$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initLoadTallerInspeccion),
      concatMap(() =>
        this._inspecionesService.getTalleres().pipe(
          map((response) => {
            if (response.code === 200 && Array.isArray(response.result) && response.result.length > 0)
              return GlobalApiActions.loadSuccessloadTallerInspeccion({
                talleresInspeccion: response.result as TallerInspeccionObject[],
              });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  notifyApiError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          GlobalApiActions.loadErrorCatalogueCHostCRM,
          GlobalApiActions.loadErrorCatalogueState,
          GlobalApiActions.loadErrorCatalogueBanks
        ),
        tap((action) => this._notificationsService.error(action.errorMessage))
      );
    },
    { dispatch: false }
  );
}
